import React, {Component} from 'react';
import UserContext from "../../../../../user-context";
// import { MaterialReactTable } from 'material-react-table';
import BarChatDispanse from "./component/BarChartDispanse";
import {Col, Row} from "react-bootstrap";
import Api from "../../../../../api/ApiUsers";
// import TariffCard from "./cards/TariffCard";
// import classes from "./modal-window/style.module.css";
// import PayBalance from "./modal-window/PayBalance";
// import ReactModal from "react-modal";
import {Box, IconButton} from "@mui/material";
import {Delete as DeleteIcon, Info as InfoIcon} from "@mui/icons-material";
import {MaterialReactTable} from "material-react-table";
import {Link} from "react-router-dom";
import ActiveRfid from "./component/ActiveRfid";
import NotActiveRfid from "./component/NotActiveRfid";
import ActiveRfidTable from "./component/ActiveRfidTable";
import HeatMap from "./component/HeatMap";
import Datepicker from "./component/DatePicker";
// import classes from "./modal-window/style.module.css";
// import ReactModal from "react-modal";
// import CreateUser from "./modal-window/CreateUser";
// import InfoUser from "./modal-window/InfoUser";

class RfidStatistic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: [],
      data_dispanse: []
    }
    // this.loadRfids = this.loadRfids.bind(this);
    // this.handleOpenPayBalance = this.handleOpenPayBalance.bind(this);
    this.updateDate = this.updateDate.bind(this);
  }
  updateDate (date) {
    this.setState({ date: date });
  }

  componentDidMount() {
    this.setState({date: [new Date().setUTCDate(new Date().getUTCDate() - 6), new Date().setUTCDate(new Date().getUTCDate())]})
  }
  //
  // loadRfids() {
  //   Api.getRfid(1).then(res => {
  //     this.setState({active_data: res})
  //     }
  //   ).catch(err => {
  //     if (err.code === 'token_not_valid') {
  //       this.context.setMyState({isLogged: false})
  //     }
  //   })
  //   Api.getRfid(0).then(res => {
  //     this.setState({notactive_data: res})
  //     }
  //   ).catch(err => {
  //     if (err.code === 'token_not_valid') {
  //       this.context.setMyState({isLogged: false})
  //     }
  //   })
  // }


  render() {
    return (
      <Row style={{ margin: "20px" }}>
        <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700"
             style={{display: 'flex', width: 'auto', marginLeft: 'auto'}}>
             {/*style={{position: "relative", width: "auto", marginLeft: "auto", right: 0}}>*/}
          <Datepicker set_date={this.updateDate}/>
        </div>
        <div className="col-span-full sm:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
          <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
            <h2 className="font-semibold text-slate-800 dark:text-slate-100">Проливы по дням</h2>
          </header>
            <BarChatDispanse date={this.state.date}/>
        </div>
        {/*<div className="col-span-full sm:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">*/}
          {/*<HeatMap/>*/}
        {/*</div>*/}
      </Row>
    )
  }
}

RfidStatistic.contextType = UserContext;

export default RfidStatistic
