import React, { Component } from 'react';
import UserContext from "../../user-context";
import "../../../scss/LoadingPage.css"

class PageLoading extends Component {

  render() {
    return (
      <div className="loader">
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="cup"><span></span></div>
      </div>
    )
  }
}

PageLoading.contextType = UserContext;

export default PageLoading
