import React, { Component } from 'react';
import UserContext from "../../user-context";
import Api from "../../api/ApiUsers";
// import { useParams } from "react-router-dom";
import {withRouter} from "../../utils/withRouter";
import Page404 from "../static-page/Page404";
import PageLoading from "../static-page/PageLoading";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parent_user_id: '',
      role: '',
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      pass_rep: '',
      loading: true,
      not_found: false,
      ttl_exp: false,
      email_exist: false,
      send_email: false
    }
    this.onChange = this.onChange.bind(this);
    this.signUp = this.signUp.bind(this);
  }

  componentDidMount() {
    const {hash, token} = this.props.params
    Api.checkLinkRegistration(hash, token).then(res => {
        if ('error' in res) {
          if (res.error === 'bad_link') {
            this.setState({not_found: true, loading: false})
          } else if (res.error === 'ttl_exp') {
            this.setState({ttl_exp: true, loading: false})
          }
        } else {
          this.setState({
            loading: false,
            role: res.role,
            parent_user_id: res.parent_user_id
          })
        }
      })

  }
  onChange(e, field_name) {
    this.setState({[field_name]: e})
  }

  signUp (e) {
    e.preventDefault();
    const {
      parent_user_id, email, first_name, last_name, password, role
    } = this.state;
    Api.signUpUser(
      parent_user_id, email, first_name, last_name, password, role
    ).then(res => {
      // console.log(res)
      if (res.status === 'Ok') {
        this.setState({send_email: true})
      } else if (res.status === 'email_exist') {
        this.setState({email_exist: true})
      }
    })

  }

  render() {
    if (this.state.not_found) {
      return <Page404/>
    } else if (this.state.loading) {
      return <PageLoading/>
    } else if (this.state.send_email) {
      return <h1>Письмо отправлено на email</h1>
    } else if (this.state.ttl_exp && !this.state.parent_user_id) {
      return <h1>TTL Link Expire</h1>
    } else {
      return (
        <div>
          <h4>Регистрация</h4>
          <form className="pt-3" onSubmit={this.signUp}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" className="form-control form-control-lg" id="email"
                     value={this.state.email || ''}
                     onChange={ (e) => this.onChange(e.target.value, 'email')}
                     placeholder="Email"
              />
              {
                this.state.email_exist ?
                  <h4>Такой email уже есть</h4>
                  :
                  <div></div>
              }
            </div>
            <div className="form-group">
              <label htmlFor="first_name">Имя</label>
              <input type="text" className="form-control form-control-lg" id="first_name"
                     value={this.state.first_name || ''}
                     onChange={ (e) => this.onChange(e.target.value, 'first_name')}
                     placeholder="Имя"
              />
            </div>
            <div className="form-group">
              <label htmlFor="last_name">Фамилия</label>
              <input type="text" className="form-control form-control-lg" id="last_name"
                     value={this.state.last_name || ''}
                     onChange={ (e) => this.onChange(e.target.value, 'last_name')}
                     placeholder="Фамилия"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" className="form-control form-control-lg" id="password"
                     value={this.state.password || ''}
                     onChange={ (e) => this.onChange(e.target.value, 'password')}
                     placeholder="Password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="repid_password">Повторите Password</label>
              <input type="password" className="form-control form-control-lg" id="repid_password"
                     onChange={ (e) => this.onChange(e.target.value, 'pass_rep')}
                     placeholder="Password"
              />
              { this.state.pass_rep === this.state.password ?
                <div></div>
                :
                <h1>Не совпадают</h1>
              }
            </div>
            <div className="mb-4">
              <div className="form-check">
                <label className="form-check-label text-muted">
                  <input type="checkbox" className="form-check-input" />
                    <i className="input-helper"></i>
                    I agree to all Terms & Conditions
                </label>
              </div>
            </div>
            <div className="my-3">
              <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      type="submit">SignUp</button>
            </div>
          </form>
        </div>
      )
    }

  }
}

SignUp.contextType = UserContext;

export default withRouter(SignUp)
