import React, { Component } from 'react';
import UserContext from "../../../../../user-context";
import Api from "../../../../../api/ApiUsers";
import { Tab, Tabs } from '@liinkiing/react-tabs'
import { YMaps, Map, ObjectManager } from '@pbe/react-yandex-maps';
import {Row} from "react-bootstrap";
// import TariffCard from "../billing/cards/#TariffCard";
import {Box, IconButton, Typography} from "@mui/material";
import {Delete as DeleteIcon, Info as InfoIcon} from "@mui/icons-material";
import {MaterialReactTable} from "material-react-table";

class CompanyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companys: '',
      tariffs: ''

    }
    //   data: [{},],
    //   rowHolding: '',
    //   showCreateCompany: false,
    //   showInfoHolding: false
    // }
    this.getTabs = this.getTabs.bind(this);
    // this.handleCloseCreateCompany = this.handleCloseCreateCompany.bind(this);
    // this.handleOpenInfoHolding = this.handleOpenInfoHolding.bind(this);
    // this.handleCloseInfoHolding = this.handleCloseInfoHolding.bind(this);

  }

  componentDidMount() {
    Api.getUserCompany().then(res => {
      // console.log(res)
      this.setState({
        companys: res,
        // tariffs: res.tariff,
      })
      }
    ).catch(err => {
      if (err.code === 'token_not_valid') {
        this.context.setMyState({isLogged: false})
      }
    })
  }


  getTabs() {
    if (this.state.companys) {
       function getColumnObject() {
          return [
            {
              accessorKey: 'name', //access nested data with dot notation
              header: 'Объект',
              size: 150,
            },
            {
              accessorKey: 'city.name',
              header: 'Города',
              size: 150,
            },
            {
              accessorKey: 'address',
              header: 'Адрес',
              size: 150,
            },
            {
              accessorKey: 'cid_count',
              header: 'Кол-во КМ',
              size: 150,
            },
            {
              accessorKey: 'contact_name',
              header: 'Контактное лицо',
              size: 150,
            },
            {
              accessorKey: 'contact_phone', //normal accessorKey
              header: 'Контактный телефон',
              size: 200,
            },
            {
              accessorKey: 'contact_email',
              header: 'Контактный email',
              size: 150,
            },
          ];
        }

      return (
        <Tabs
          initial={{opacity: 0, x: 40}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 40}}
        >
          {this.state.companys.map(function(company) {
       return (
          <Tab key={company.id}>
            <Tab.Title>{company.name}</Tab.Title>
            <Tab.Content>
              <div>Город: {company.city.name}</div>
              <div>Юридический Адрес: {company.legal_address?company.legal_address:'-'}</div>
              <div>Фактический Адрес: {company.phyzical_address?company.phyzical_address:'-'}</div>
              <div>ИНН: {company.inn}</div>
              <div>Банк: {company.bank_name?company.bank_name:'-'}</div>
              <div>БИК: {company.bik?company.bik:'-'}</div>
              <div>КПП: {company.kpp?company.kpp:'-'}</div>
              <div>КС: {company.k_s?company.k_s:'-'}</div>
              <div>Контактное лицо: {company.contact_name?company.contact_name:'-'}</div>
              <div>Контактный телефон: {company.contact_phone?company.contact_phone:'-'}</div>
              <div>Контактный email: {company.contact_email?company.contact_email:'-'}</div>

              {company.objects?
                <MaterialReactTable
                  columns={getColumnObject()}
                  data={company.objects}
                  // rowCount={this.state.rowCount}
                  // pageCount={this.state.pageCount}
                  enableFullScreenToggle={false}
                  enableExpandAll={false}
                  // onPaginationChange={e => this.handlePaginationChange(e(this))}
                  // onPaginationChange={}
                  initialState={{
                    density: 'compact',
                    // pagination:
                    //   pag?pag:{pageSize:10, pageIndex:0}
                      // pageSize: this.state.page_size?this.state.page_size:10,
                      // pageIndex: pag1
                      // pageIndex: this.state.page_index?this.state.page_index:0

                  }}
                  // state={{pagination: pag?pag:{pageSize:10, pageIndex:0}}}
                  //     pageSize: this.state.page_size?this.state.page_size:10,
                  //     pageIndex: this.state.page_index?this.state.page_index:0
                  //   }}}
                  // renderDetailPanel={({ row }) => {
                  //   if (row.getIsExpanded()) {
                  //     return (
                  //       <>
                  //       <Box
                  //         sx={{
                  //           display: 'grid',
                  //           margin: 'auto',
                  //           gridTemplateColumns: '1fr 1fr',
                  //           width: '100%',
                  //         }}
                  //       >
                  //         <div>Параметры группы</div>
                  //         <div>
                  //         <Typography>Кредитная: {row.original.group.is_credit? 'Да': 'Нет'}</Typography>
                  //         <Typography>Все напитки: {row.original.group.all_product? 'Да': 'Нет'}</Typography>
                  //         {row.original.group.all_product? '' :
                  //           <Typography>Товары: {row.original.group.device_product.map(
                  //             (row) => {
                  //               return row.product + ' '
                  //             }
                  //             )
                  //           }</Typography>
                  //         }
                  //         </div>
                  //
                  //         {/*<Typography>Контактное email: {row.original.contact_email}</Typography>*/}
                  //       </Box>
                  //         {/*<div>Товары</div>*/}
                  //         {/*<ProductCodeTable dataMatrix={row.original} />*/}
                  //       </>
                  //     )
                  //   } else {
                  //     return <div>Not expanded</div>
                  //   }
                  // }}
                  enableDensityToggle={false}
                  positionActionsColumn="last"
                  // tableInstanceRef={this.myRef}
                  enableRowActions
                  renderRowActions={({ row, table }) => {
                    if (row) {
                      <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
                        <IconButton
                          color="primary"
                          // onClick={() =>
                          //   this.setState({
                          //     rowObject: row.original,
                          //     showInfoObject: true
                          //   })
                          //
                          // }
                        >
                          <InfoIcon/>
                        </IconButton>
                        <IconButton
                          color="error"
                          // onClick={() => {
                          //   data.splice(row.index, 1); //assuming simple data table
                          //   setData([...data]);
                          // }}
                        >
                          <DeleteIcon/>
                        </IconButton>
                      </Box>
                    }
                  }
                  }
                />
                :
                <></>
              }
            </Tab.Content>
          </Tab>
         )
          })}
        </Tabs>
        )
    } else {
      return <div>У Вас нет Компаний</div>
    }
  }


  render() {

    return  (
      <Row style={{ margin: "20px" }}>
        {this.getTabs()}




        {/*<YMaps>*/}
        {/*      <Map*/}
        {/*        defaultState={{ center: [55.75, 37.57], zoom: 10 }}*/}
        {/*        width={ "100%" }*/}
        {/*        >*/}
              {/*<ObjectManager*/}
              {/*      options={{*/}
              {/*          clusterize: true,*/}
              {/*          gridSize: 32,*/}
              {/*      }}*/}
              {/*      objects={{*/}
              {/*          openBalloonOnClick: true,*/}
              {/*          preset: 'islands#greenDotIcon',*/}
              {/*      }}*/}
              {/*      clusters={{*/}
              {/*          preset: 'islands#redClusterIcons',*/}
              {/*      }}*/}
              {/*      defaultFeatures={{*/}
              {/*          type: 'FeatureCollection',*/}
              {/*          features: [*/}
              {/*              {*/}
              {/*                  type: 'Feature',*/}
              {/*                  id: 1,*/}
              {/*                  geometry: {*/}
              {/*                      type: 'Point',*/}
              {/*                      coordinates: [55.75, 37.57]*/}
              {/*                  }*/}
              {/*              },*/}
              {/*              {*/}
              {/*                  type: 'Feature',*/}
              {/*                  id: 2,*/}
              {/*                  geometry: {*/}
              {/*                      type: 'Point',*/}
              {/*                      coordinates: [56.75, 38.57]*/}
              {/*                  }*/}
              {/*              },*/}
              {/*              {*/}
              {/*                  type: 'Feature',*/}
              {/*                  id: 3,*/}
              {/*                  geometry: {*/}
              {/*                      type: 'Point',*/}
              {/*                      coordinates: [56.75, 38.57]*/}
              {/*                  }*/}
              {/*              },*/}
              {/*          ]*/}
              {/*      }}*/}
              {/*      modules={[*/}
              {/*          'objectManager.addon.objectsBalloon',*/}
              {/*          'objectManager.addon.objectsHint',*/}
              {/*      ]}*/}
              {/*  />*/}
          {/*    </Map>*/}

          {/*</YMaps>*/}
      </Row>
    )
  }
}

CompanyPage.contextType = UserContext;

export default CompanyPage
