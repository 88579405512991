import React, { Component } from 'react';
import UserContext from "../../../../user-context";
import styles from './styles.module.css'
import {Row, Dropdown} from "react-bootstrap";
import {
  Typography
} from '@mui/material';


class HeaderOwner extends Component {
   constructor(props) {
     super(props);
     this.logOut = this.logOut.bind(this);
   }

  logOut(e) {
    this.context.setMyState({isLogged: false, user: ''})
    localStorage.clear()
  }


  render() {
    return (
      <Row className={styles.headerDashboard}>
        <div className="col align-self-start">
          <Typography variant="h4">Dashboard</Typography>
        </div>
        <div className="col-2 align-self-end">
          <Row>
            {/* <div className="col align-self-center">*/}
            {/*   <Typography>Роль: {this.context.user.role}</Typography>*/}
            {/*</div>*/}
          <div className="col align-self-end">
          <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                  <img className={styles.avatar} src={require("../../../../image/account_icon.png")} alt="Profile" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                  <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom">
                    <div className="row">
                      <div className="col" align="center">
                        {this.context.user.first_name}
                      </div>
                      <div className="col" align="center">
                      {this.context.user.last_name}
                      </div>
                    </div>
                  </Dropdown.Item>
                  {/*<Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0 mt-2" as={Link} to="/settings">*/}
                  {/*  Настройки*/}
                  {/*</Dropdown.Item>*/}
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={this.logOut}>
                    Выйти
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            </Row>
        </div>
      </Row>
    )
  }
}

HeaderOwner.contextType = UserContext;

export default HeaderOwner
