import React, { Component } from 'react';
import UserContext from "../../user-context";

class Page404 extends Component {

  render() {
    return (
      <div>
        <h3>404 page not found</h3>
        <p>We are sorry but the page you are looking for does not exist.</p>
      </div>
    )
  }
}

Page404.contextType = UserContext;

export default Page404
