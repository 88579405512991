// import logo from '../logo.svg';
import './App.css';
import React, {Component} from 'react';
import UserContext from "./user-context";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Api from "./api/ApiUsers";
import Dashboard from "./pages/dashboard/Dashboard";
// import Dashboard2 from "./pages/dashboard/Dashboard2";
import Login from "./pages/auth-pages/Login";
import SignUp from "./pages/auth-pages/SignUp";
import PageLoading from "./pages/static-page/PageLoading";
import ConfirmSignUp from "./pages/auth-pages/ConfirmSignUp";


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      isLogged: null,
      access: localStorage.getItem('_stoda_'),
      setMyState: this.setMyState.bind(this)
    }
    this.RequireAuth = this.RequireAuth.bind(this);
    this.CheckAuth = this.CheckAuth.bind(this);
  }

  setMyState(data) {
    this.setState(data);
  };

  checkUser() {
    if (this.state.access) {
      Api.getUserMeData().then(res => {
          this.setState({
            'isLogged': true,
            'user': {
              'id': res.id,
              'email': res.email,
              'first_name': res.first_name,
              'last_name': res.last_name,
              'role': res.role,
              'holding': res.holding,
              'company': res.company,
              'tariffs': res.tariffs,
              'services': res.services,
              'url_registration': res.url_registration,
              'url_expires': res.url_expires,
              'url_for': res.url_for
            }
          })
      }).catch(err => {
        // console.log(err)
        this.setState({'isLogged': false})
      })
    } else {
      this.setState({'isLogged': false})
    }

  }

  RequireAuth ({ children }) {
    if (this.state.isLogged) {
      return children
    } else if (this.state.isLogged === null){
      this.checkUser()
      return <PageLoading/>
    }
    return <Navigate to="/login" replace />;
  }

  CheckAuth ({ children }) {
    if (this.state.isLogged) {
      return <Navigate to="/" replace />;
    } else if (this.state.isLogged === null){
      this.checkUser()
      return <PageLoading/>
    }
    return children
  }

  render () {
    return (
        <UserContext.Provider value={this.state}>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={
                  <this.RequireAuth>
                    <Dashboard/>
                  </this.RequireAuth>
                }
              />
              <Route path="/login" element={
                  <this.CheckAuth>
                    <Login/>
                  </this.CheckAuth>
                }
              />
              {/*<Route path="signup/:hash/:token" element={<SignUp/>}/>*/}
              {/*<Route path="confirm/:hash/:token" element={<ConfirmSignUp/>}/>*/}
            </Routes>
          </BrowserRouter>
        </UserContext.Provider>
    );
  }
}
export default App;


