import React, {Component} from 'react';
import UserContext from "../../../../../user-context";
import Select from 'react-select'
// import { MaterialReactTable } from 'material-react-table';
// import {Row} from "react-bootstrap";
// import Api from "../../../../../api/ApiUsers";
// import classes from "./modal-window/style.module.css";
// import ReactModal from "react-modal";
// import CreateUser from "./modal-window/CreateUser";
// import InfoUser from "./modal-window/InfoUser";
import Collapse from '@kunukn/react-collapse';
import "./app.css";
import cx from "classnames";
import ExampleExcel from './example.xlsx';
import Api from "../../../../../api/ApiUsers";
import {Form} from "react-bootstrap";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import {QRCodeSVG} from 'qrcode.react';

class AddPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excelOpen: false,
      scanOpen: false,
      file: null,
      company: '',
      uploadDisabled: true,
      uploadDisabledComp: false,
      statusUpload: '',
      type_scan: '',
      loadActivator: true,
      cardActivator: '',
      cardType: '',
      cardNumber: '',
      cardDate: '',
      GuidStatus: '',
      GuidActivator: '',
      GuidDate: '',
      RfidStatus: '',
      RfidActivator: '',
      RfidDate: '',
      // groupCard: '',
      // showCardGroup: false,
      // allProductChecked: true,
      // products: '',
      // showProduct: false

      // data: [{},],
      // showCreateUser: false,
      // showInfoUser: false,
      // rowUser: ''
    }
    this.toggle = this.toggle.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.getCompanyUser = this.getCompanyUser.bind(this);
    this.checkActivator = this.checkActivator.bind(this);
    this.getCardActivator = this.getCardActivator.bind(this);
    this.getNewActivator = this.getNewActivator.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.getTypeRfidGuid = this.getTypeRfidGuid.bind(this);
    this.getGuidActivate = this.getGuidActivate.bind(this);
    this.getResultActivator = this.getResultActivator.bind(this);
    this.getRfidActivate = this.getRfidActivate.bind(this);
    this.prolongateCard = this.prolongateCard.bind(this);
  }

  componentDidMount() {
    if (this.context.user.company.length > 1) {
      this.setState({uploadDisabledComp: true});
    }
    // else {
      // this.setState({showCardGroup: true});
    }
  //   // Api.getMyUsersList().then(res => {
  //   //   var new_data = []
  //   //   for (var i in res) {
  //   //     new_data[i] = res[i]
  //   //     new_data[i].holdings = res[i].holdings.map(({ name }) => name).join()
  //   //   }
  //   //   this.setState({data: new_data})
  //   //   }
  //   // ).catch(err => {
  //   //   if (err.code === 'token_not_valid') {
  //   //     this.context.setMyState({isLogged: false})
  //   //   }
  //   // })
  // }

  handleFileChange(e){
    if (e.target.files[0]) {
      this.setState({file: e.target.files[0], uploadDisabled: false});
    } else {
      this.setState({uploadDisabled: true});
    }
  }

  handleUpload (e) {
    e.preventDefault();
    // const data = new FormData(e.target);
    // console.log(data.get('form-field-name'))
    if (this.state.file) {
      this.setState({statusUpload: 'Uploads'});
      const formData = new FormData(e.target);
      // formData.append("file_excel", this.state.file);
      // formData.append("company", this.state.company);
      Api.uploadRfidExcel(formData).then(res => {
        if (res.status === 'ok') {
          this.setState({statusUpload: 'Complited'})
        } else {
          this.setState({statusUpload: res.msg})
        }
      }
    ).catch(err => {
      this.setState({statusUpload: err});
      if (err.code === 'token_not_valid') {
        this.context.setMyState({isLogged: false})
      }
    })
    }
  }

  toggle(button) {
    if (button === 'excel') {
      if (this.state.excelOpen) {
        this.setState({excelOpen: false})
      } else {
        this.setState({excelOpen: true})
      }
    } else if (button === 'scan') {
      if (this.state.scanOpen) {
        this.setState({scanOpen: false})
      } else {
        this.setState({scanOpen: true})
        this.checkActivator()
      }
    }
  }

  handleSelectChange(e) {
    this.setState({
      company: e.value, uploadDisabledComp: false,
    })
    this.checkActivator()

  }

  getCompanyUser (){
    if (this.context.user.company.length > 1) {
      return (
        <div>
          <label htmlFor='company_id'>Так как у Вас несколько Компаний, выберите, для какой необходимо добавить Rfid</label>
          <Select
            name="company_id"
            placeholder="Выберите Компанию"
            searchable={false}
            // defaultValue={{value: this.context.user.company[0].id, label: this.context.user.company[0].name}}
            onChange={(e) => this.handleSelectChange(e)}
            options={
              this.context.user.company.map( function(item) {
                     return {value: item.id, label: item.name}
                })
            }
          />
        </div>
      )
    }
  }

  checkActivator() {
      let status = false
      if (this.context.user.company.length > 1) {
        if (this.state.company) {
          status = true
        }
      } else {
        status = true
      }
      if (status) {
        this.setState({loadActivator: false})
        Api.getActivator(this.state.company).then(res => {
            if (res.status === 'ok') {
              this.setState({
                cardActivator: true,
                cardType: res.type,
                cardNumber: res.number,
                cardDate: res.date
              })
            }
          }
        ).catch(err => {
          this.setState({statusUpload: err});
          if (err.code === 'token_not_valid') {
            this.context.setMyState({isLogged: false})
          }
        })
      }
  }

  getTypeRfidGuid() {
    if (this.state.type_scan === 'guid') {
      return(
        <Button
              color="success"
              disabled={this.context.user.company.length > 1 && this.state.company === ''}
              variant="contained"
              onClick={() => this.getGuidActivate()}
            >
              Показать QR
        </Button>
      )
    } else if (this.state.type_scan === 'rfid') {
      return(
        <Form onSubmit={(e) => this.getRfidActivate(e)}>
          <div>
            <label>
              Введите номер Rfid
              <input required name="rfid" type="text"/>
            </label>
          </div>
          <div>
            <Button color="success" variant="contained" type="submit">
                Отправить
            </Button>
          </div>
        </Form>
      )
    }
  }

  getGuidActivate() {
    Api.getGuidActivator(this.state.company).then(res => {
        if (res.status === 'ok') {
          this.setState({GuidActivator: res.msg, GuidDate: res.date})
        } else {
          this.setState({GuidStatus: res.msg})
        }
      }
    ).catch(err => {
      this.setState({statusUpload: err});
      if (err.code === 'token_not_valid') {
        this.context.setMyState({isLogged: false})
      }
    })
  }

  getRfidActivate(e) {
    e.preventDefault()
    const formData = new FormData(e.target);
    formData.append('company_id', this.state.company)
    Api.getRfidActivator(formData).then(res => {
        if (res.status === 'ok') {
          this.setState({RfidActivator: res.msg, RfidDate: res.date})
        } else {
          this.setState({RfidStatus: res.msg})
        }
      }
    ).catch(err => {
      this.setState({statusUpload: err});
      if (err.code === 'token_not_valid') {
        this.context.setMyState({isLogged: false})
      }
    })
  }

  getResultActivator() {
    if (this.state.type_scan === 'guid') {
      if (this.state.GuidActivator) {
        return (
          <>
            <div>
              <div>Порядок сканирования:</div>
              <div>1. Отсканируйте Мастер ключ и дождитесь подтверждения</div>
              <div>2. Отсканирйте карту, которую необходимо добавить и дождитесь подтверждения</div>
              <div>3. После того как карты все добавлены, обязательно отсканируйет повторно Мастер ключ</div>
            </div>
            <div>
              Мастер ключ действует до: {this.state.GuidDate}
            </div>
            <div>
              <QRCodeSVG value={this.state.GuidActivator}/>
            </div>
          </>
        )
      } else if (this.state.GuidStatus) {
        return (<>{this.state.GuidStatus}</>)
      }
    } else if (this.state.type_scan === 'rfid') {
      if (this.state.RfidActivator) {
        return (
          <>
            <div>
              <div>Порядок сканирования:</div>
              <div>1. Отсканируйте Мастер ключ и дождитесь подтверждения</div>
              <div>2. Отсканирйте карту, которую необходимо добавить и дождитесь подтверждения</div>
              <div>3. После того как карты все добавлены, обязательно отсканируйет повторно Мастер ключ</div>
            </div>
            <div>
              Мастер ключ действует до: {this.state.RfidDate}
            </div>
          </>
        )
      } else if (this.state.RfidStatus) {
        return (<>{this.state.RfidStatus}</>)
      }
    }
  }

  prolongateCard(type_card) {
    Api.getProlongate(type_card, this.state.cardNumber).then(res => {
            if (res.status === 'ok') {
              this.setState({
                cardDate: res.date
              })
            }
          }
        ).catch(err => {
          this.setState({statusUpload: err});
          if (err.code === 'token_not_valid') {
            this.context.setMyState({isLogged: false})
          }
        })
  }

  getCardActivator() {
    if (this.state.cardType === 'rfid') {
      return (
          <>
            <div>
              <div>Порядок сканирования:</div>
              <div>1. Отсканируйте Мастер ключ и дождитесь подтверждения</div>
              <div>2. Отсканирйте карту, которую необходимо добавить и дождитесь подтверждения</div>
              <div>3. После того как карты все добавлены, обязательно отсканируйет повторно Мастер ключ</div>
            </div>
            <div>
               <label>
              Номер Rfid
              <input readOnly name="rfid" type="text" value={this.state.cardNumber}/>
            </label>
            </div>
            <div>
              Мастер ключ действует до: {this.state.cardDate}
              <Button
              color="success"
              variant="contained"
              onClick={() => this.prolongateCard('rfid')}
            >
              Продлить
            </Button>
            </div>
            <Button
              color="info"
              variant="contained"
              onClick={() => {this.setState({cardActivator: false})}}
            >
              Получить новый
            </Button>
          </>
        )
    } else {
      return (
          <>
            <div>
              <div>Порядок сканирования:</div>
              <div>1. Отсканируйте Мастер ключ и дождитесь подтверждения</div>
              <div>2. Отсканирйте карту, которую необходимо добавить и дождитесь подтверждения</div>
              <div>3. После того как карты все добавлены, обязательно отсканируйет повторно Мастер ключ</div>
            </div>
            <div>
              Мастер ключ действует до: {this.state.cardDate}
              <Button
              color="info"
              variant="contained"
              onClick={() => this.prolongateCard('guid')}
            >
              Продлить
            </Button>
            </div>
            <div>
              <QRCodeSVG value={this.state.cardNumber}/>
            </div>
            <Button
              color="success"
              variant="contained"
              onClick={() => {this.setState({cardActivator: false})}}
            >
              Получить новый
        </Button>
          </>
        )
    }
  }


  getNewActivator() {
    return (
      <>
        <div>Выберите метод сканирования Мастер ключа</div>
        <div>
          <label>
            <input name="type_scan" value="rfid" onChange={() => {
              this.setState({type_scan: 'rfid'})}} type="radio"/>
            Rfid
          </label>
        </div>
        <div>
          <label>
            <input name="type_scan" value="guid" onChange={() => {
              this.setState({type_scan: 'guid'})}} type="radio"/>
              Guid
          </label>
        </div>
        <div>
          {this.getTypeRfidGuid()}
        </div>
        <div>
          {this.getResultActivator()}
        </div>
      </>
    )
  }

  render() {

    return (
      <>
        <div className='card' style={{ margin: "20px", padding: "0px", width: "auto" }}>
          <button
            className={cx("app__toggle", {
              "app__toggle--active": this.state.excelOpen
            })}
            onClick={() => this.toggle('excel') }
          >
            <span className="app__toggle-text">Добавить через загрузку Excel файла</span>
            <div className="rotate90">
              <svg
                className={cx("icon", { "icon--expanded": this.state.excelOpen })}
                viewBox="6 0 12 24"
              >
                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
              </svg>
            </div>
          </button>
          <Collapse
            isOpen={this.state.excelOpen}
            className={
              "app__collapse app__collapse--gradient " +
              (this.state.excelOpen ? "app__collapse--active" : "")
            }
          >
            <div className="app__content">
              <Form onSubmit={(e) => this.handleUpload(e)}>
              Для добавления, необходимо правильно заполнить файла Excel.
              Образец файла можно скачать <a
                href={ExampleExcel}
                download="Example.xlsx"
                target="_blank"
                rel="noreferrer"
            >отсюда</a>
              <div>
                <input id="file" type="file" name="file_excel" onChange={(e) => this.handleFileChange(e)} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
              </div>
              {this.getCompanyUser()}
              {/*{this.getCardGroup()}*/}
              {/*{this.getProduct()}*/}
              {/*<button color="secondary" disabled={this.state.uploadDisabled || this.state.uploadDisabledComp} onClick={() => this.handleUpload()}>Загрузить файл</button>*/}
              <button color="secondary" type='submit' disabled={this.state.uploadDisabled || this.state.uploadDisabledComp}>Загрузить файл</button>
              </Form>
              {
                this.state.statusUpload ?
                  <>
                    <div>
                      Статус добавления: {this.state.statusUpload}
                    </div>
                    <div>
                      <Link to=".." relative="path">
                          <Button color="info" variant="contained">Вернуться к таблице</Button>
                      </Link>
                    </div>
                  </>
                 : <></>
              }


            </div>
          </Collapse>
        </div>

        <div className='card' style={{ margin: "20px", padding: "0px", width: "auto" }}>
          <button
            className={cx("app__toggle", {
              "app__toggle--active": this.state.excelOpen
            })}
            onClick={() => this.toggle('scan') }
          >
            <span className="app__toggle-text">Добавить через сканирование</span>
            <div className="rotate90">
              <svg
                className={cx("icon", { "icon--expanded": this.state.scanOpen })}
                viewBox="6 0 12 24"
              >
                <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
              </svg>
            </div>
          </button>
          <Collapse
            isOpen={this.state.scanOpen}
            className={
              "app__collapse app__collapse--gradient " +
              (this.state.scanOpen ? "app__collapse--active" : "")
            }
          >
            <div className="app__content">
              <div>
               {this.getCompanyUser()}
                {this.state.cardActivator ? this.getCardActivator() : this.getNewActivator()}
              </div>
            </div>
          </Collapse>
        </div>

      </>
    )
  }
}

AddPage.contextType = UserContext;

export default AddPage
