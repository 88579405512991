import React, { Component } from 'react';
import UserContext from "../../user-context";
import Api from "../../api/ApiUsers";
import {withRouter} from "../../utils/withRouter";
import ReactModal from 'react-modal';
import "../../../scss/Login.scss"
import { ReactComponent as EmailIcon } from "../../image/email.svg"
import { ReactComponent as PassIcon } from "../../image/password.svg"
import DigiLogo from "../../image/digicoffee.jpg"
// import LostPassword from "./modal-window/LostPassword";
// import classes from "./modal-window/style.module.css";

import {
  Checkbox,
  Typography
} from '@mui/material';

ReactModal.setAppElement('#root');

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      remember: 0,
      error: '',
      showLostPass: false
    }
    this.logIn = this.logIn.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.handleOpenLostPass = this.handleOpenLostPass.bind(this);
    this.handleCloseLostPass= this.handleCloseLostPass.bind(this);
  }


  onChange(e, field_name){
    this.setState({[field_name]: e})
  }


  getUserData() {
    Api.getUserMeData().then(res => {
          this.context.setMyState({'isLogged': true})
          this.context.setMyState({
              user: {
                id: res.id,
                email: res.email,
                first_name: res.first_name,
                last_name: res.last_name,
                role: res.role,
                holding: res.holding,
                company: res.company,
                tariffs: res.tariffs,
                services: res.services,
                url_registration: res.url_registration,
                url_expires: res.url_expires,
                url_for: res.url_for
              }
            }
          );
        }).catch(err => {
          this.context.setMyState({'isLogged': false})
        })
  }

  logIn(e) {
    e.preventDefault();
    const {email, password, remember} = this.state;
    Api.loginUser(email, password, Number(remember)).then(res => {
      if (res.access) {
        localStorage.setItem('_stoda_', res.access)
        this.getUserData()
      } else {
        this.setState({'error': true})
        this.context.setMyState({'isLogged': false})
      }
    }).catch(err => {
      this.setState({'error': true})
      this.context.setMyState({'isLogged': false})
    })
  }

  handleOpenLostPass () {
    this.setState({ showLostPass: true });
  }

  handleCloseLostPass () {
    this.setState({ showLostPass: false });
  }

  get_error () {
    return <div style={{alignSelf: "flex-end"}}><Typography variant="caption" color="red">Аккаунт не найден</Typography></div>
  }

  render() {
    return (
      <body-login>
        <div className="session">
          <div className="left">
            <img src={DigiLogo} width="100" height="100"/>
          </div>
          <form onSubmit={this.logIn} className="log-in" autoComplete="off">
            <h4><span>DigiCoffee</span></h4>
            <div className="floating-label">
              <input placeholder="Email" type="email" name="email" id="email" autoComplete="email"
               value={this.state.email || ''} onChange={ (e) => this.onChange(e.target.value, 'email')} required/>
                <label htmlFor="email">Email:</label>
                <div className="icon">
                  <EmailIcon enableBackground="new 0 0 100 100">
                    <g transform="translate(0 -952.36)">
                    <path
                      d="m17.5 977c-1.3 0-2.4 1.1-2.4 2.4v45.9c0 1.3 1.1 2.4 2.4 2.4h64.9c1.3 0 2.4-1.1 2.4-2.4v-45.9c0-1.3-1.1-2.4-2.4-2.4h-64.9zm2.4 4.8h60.2v1.2l-30.1 22-30.1-22v-1.2zm0 7l28.7 21c0.8 0.6 2 0.6 2.8 0l28.7-21v34.1h-60.2v-34.1z"/>
                    </g>
                    <rect className="st0" width="100" height="100"/>
                  </EmailIcon>
                </div>
            </div>
            <div className="floating-label">
              <input placeholder="Password" type="password" name="password" id="password" autoComplete="off"
              value={this.state.password || ''} onChange={ (e) => this.onChange(e.target.value, 'password')} required/>
                <label htmlFor="password">Password:</label>
                <div className="icon">
                  <PassIcon enableBackground="new 0 0 24 24">
                    <rect className="st0" width="24" height="24"/>
                    <path className="st1" d="M19,21H5V9h14V21z M6,20h12V10H6V20z"/>
                    <path className="st1"
                          d="M16.5,10h-1V7c0-1.9-1.6-3.5-3.5-3.5S8.5,5.1,8.5,7v3h-1V7c0-2.5,2-4.5,4.5-4.5s4.5,2,4.5,4.5V10z"/>
                    <path className="st1"
                          d="m12 16.5c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5 1.5 0.7 1.5 1.5-0.7 1.5-1.5 1.5zm0-2c-0.3 0-0.5 0.2-0.5 0.5s0.2 0.5 0.5 0.5 0.5-0.2 0.5-0.5-0.2-0.5-0.5-0.5z"/>
                  </PassIcon>
                </div>
            </div>
            {this.state.error ? this.get_error() : <></>}
             <div style={{alignSelf: "flex-end"}}>
                <label htmlFor="rememberMe">Запомнить меня</label>
                <Checkbox id="rememberMe" value={this.state.remember || 0} onChange={ (e) => this.onChange(e.target.checked, 'remember')} type="checkbox"/>
              </div>

            <button className='button-login' type="submit">Войти</button>
          </form>
        </div>
      </body-login>
      // <div className="container">
      //   <div className="row">
      //     <div className="col-sm">
      //                    <Stack spacing={1} sx={{ mb: 3 }}>
      //                      <Typography variant="h4">
      //                       Авторизация
      //                     </Typography>
      //                    </Stack>
      //                       <form noValidate // onSubmit={formik.handleSubmit}
      //                       >
      //                         <Stack spacing={3}>
      //                           <TextField
      //                             // error={!!(formik.touched.email && formik.errors.email)}
      //                             fullWidth
      //                             // helperText={formik.touched.email && formik.errors.email}
      //                             label="Email Address"
      //                             name="email"
      //                             // onBlur={formik.handleBlur}
      //                             // onChange={formik.handleChange}
      //                             type="email"
      //                             // value={formik.values.email}
      //                           />
      //                           <TextField
      //                             // error={!!(formik.touched.password && formik.errors.password)}
      //                             fullWidth
      //                             // helperText={formik.touched.password && formik.errors.password}
      //                             label="Password"
      //                             name="password"
      //                             // onBlur={formik.handleBlur}
      //                             // onChange={formik.handleChange}
      //                             type="password"
      //                             // value={formik.values.password}
      //                           />
      //                         </Stack>
      //                         {/*<FormHelperText sx={{ mt: 1 }}>*/}
      //                         {/*  Optionally you can skip.*/}
      //                         {/*</FormHelperText>*/}
      //                         {/*{formik.errors.submit && (*/}
      //                         {/*  <Typography*/}
      //                         {/*    color="error"*/}
      //                         {/*    sx={{ mt: 3 }}*/}
      //                         {/*    variant="body2"*/}
      //                         {/*  >*/}
      //                         {/*    {formik.errors.submit}*/}
      //                         {/*  </Typography>*/}
      //                         {/*)}*/}
      //                         <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
      //                           Continue
      //                         </Button>
      //                       </form>
      //
      //                     {/*<form className="pt-3" onSubmit={this.logIn}>*/}
      //                     {/*  <div className="form-group">*/}
      //                     {/*    <label htmlFor="exampleInputEmail">Email</label>*/}
      //                     {/*    <div className="input-group">*/}
      //                     {/*      <input type="email" className="form-control form-control-lg border-left-0" id="exampleInputEmail"*/}
      //                     {/*         value={this.state.email || ''} onChange={ (e) => this.onChange(e.target.value, 'email')} placeholder="Email" required/>*/}
      //                     {/*    </div>*/}
      //                     {/*  </div>*/}
      //                     {/*  <div className="form-group">*/}
      //                     {/*    <label htmlFor="exampleInputPassword">Password</label>*/}
      //                     {/*    <div className="input-group">*/}
      //                     {/*      <input type="password" className="form-control form-control-lg border-left-0"*/}
      //                     {/*             value={this.state.password || ''} onChange={ (e) => this.onChange(e.target.value, 'password')}*/}
      //                     {/*             id="exampleInputPassword" placeholder="Password" autoComplete="on" required/>*/}
      //                     {/*    </div>*/}
      //                     {/*    <div className="my-2 d-flex justify-content-between align-items-center">*/}
      //                     {/*      <label htmlFor="rememberMe">Запомнить меня</label>*/}
      //                     {/*      <input id="rememberMe" value={this.state.remember || 0} onChange={ (e) => this.onChange(e.target.checked, 'remember')} type="checkbox"/>*/}
      //                     {/*    </div>*/}
      //                     {/*    {this.state.error ? this.get_error() : <></>}*/}
      //                     {/*    <div className="my-2 d-flex justify-content-between align-items-center">*/}
      //                     {/*      <div className="form-check">*/}
      //                     {/*        <button type="button" onClick={this.handleOpenLostPass}>Забыли пароль?</button>*/}
      //                     {/*      </div>*/}
      //                     {/*    </div>*/}
      //                     {/*    <div className="my-3">*/}
      //                     {/*      <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"*/}
      //                     {/*              type="submit">Войти</button>*/}
      //                     {/*    </div>*/}
      //                     {/*  </div>*/}
      //                     {/*</form>*/}
      //
      //
      //
      //
      //     </div>
      //     <div className="col-sm">
      //       LKldfasdklasdadv
      //     </div>
      //   </div>
      //   <ReactModal
      //        isOpen={this.state.showLostPass}
      //        portalClassName={classes.ModalPass}
      //        overlayClassName={classes.BackdropPass}
      //        // contentLabel="Minimal Modal Example"
      //        onRequestClose={this.handleCloseLostPass}
      //        shouldCloseOnOverlayClick={true}
      //     >
      //     <LostPassword closeModal={this.handleCloseLostPass}/>
      //     {/*<div>*/}
      //     {/*  <button onClick={this.handleCloseLostPass}>Close Window</button>*/}
      //     {/*</div>*/}
      //   </ReactModal>
      // </div>
    )
  }
}

Login.contextType = UserContext;

export default withRouter(Login)
