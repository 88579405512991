import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import ApiUsers from "../../../../../../api/ApiUsers";
import UserContext from "../../../../../../user-context";


// const data1 = [
//   {
//     'date': single_date.strftime('%m/%d'),
//     'dispanse': dispanse_count,
//     'scan': scan
//   },
// ];

const renderCustomizedLabelScan = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;
  if (value) {
    return (
      <g>
        <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#27E9ECFF"/>
        <text x={x + width / 2} y={y - radius} fill="#000000" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      </g>
    );
  }
};

const renderCustomizedLabelDis = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;
  if (value) {
    return <g>
          <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#47EF2CFF"/>
          <text x={x + width / 2} y={y - radius} fill="#000000" textAnchor="middle" dominantBaseline="middle">
            {value}
          </text>
        </g>
  }
};

class BarChatDispanse extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      date: ''
    }
  }

  componentDidMount() {
    ApiUsers.getRfidDispanseByDay(
      new Date().setUTCDate(new Date().getUTCDate() - 6),
      new Date().setUTCDate(new Date().getUTCDate())
    ).then(res => {
      this.setState({data: res})
      this.setState({date: this.props.date})
      }
    ).catch(err => {
      if (err.code === 'token_not_valid') {
        this.context.setMyState({isLogged: false})
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.date !== this.props.date) {
      ApiUsers.getRfidDispanseByDay(
        this.props.date[0],
        this.props.date[1],
        Intl.DateTimeFormat().resolvedOptions().timeZone
      ).then(res => {
          this.setState({data: res})
          this.setState({date: this.props.date})
        }
      ).catch(err => {
        if (err.code === 'token_not_valid') {
          this.context.setMyState({isLogged: false})
        }
      })
    }
  }

  render() {
    return (
      <div style={{height: '500px'}}>
      <ResponsiveContainer width="100%">
        <BarChart
          width={600}
          height={400}
          data={this.state.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis type="number" domain={['auto', 'dataMax + 1']} allowDecimals={false}/>
          <Tooltip />
          <Legend formatter={(value, entry, index) => <span style={{color: '#000000'}}>{value}</span>}/>
          <Bar dataKey="dispanse" fill="#47EF2CFF" minPointSize={5} name='Проливы'>
            <LabelList dataKey="dispanse" content={renderCustomizedLabelDis} />
          </Bar>
          <Bar dataKey="scan" fill="#27E9ECFF" minPointSize={5} name='Сканирования'>
            <LabelList dataKey="scan" content={renderCustomizedLabelScan} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      </div>
    );
  }
}

BarChatDispanse.contextType = UserContext;

export default BarChatDispanse