import React, { Component } from 'react';
import UserContext from "../../../user-context";
import HeaderOwner from "./component/HeaderOwner";
// import {ProSidebarProvider} from "react-pro-sidebar";
import SideBarOwner from "./component/SideBarOwner";
import {Route, Routes} from "react-router-dom";
import BillingContent from "./content/billing/BillingContent";
import CompanyPage from "./content/company/CompanyPage"
import RfidPage from "./content/skud/RfidPage"
import RfidAddPage from "./content/skud/AddPage"
import RfidStatistic from "./content/skud/RfidStatistic";
// import ActivatePage from "./content/skud/ActivatePage";
// import UsersTariffs from "../owner/content/users/UsersTariffs";
// import HoldingPage from "../owner/content/corporations/HoldingPage";
// import CompanyPage from "../owner/content/corporations/CompanyPage";
// import ObjectPage from "../owner/content/corporations/ObjectPage";
// import TradeMatrixPage from "../owner/content/planogramma/TradeMatrixPage";
// import ControllerPage from "../owner/content/controllers/ControllersPage";
// import FreeControllerPage from "../#owner/content/controllers/FreeControllersPage";
// import MapPage from "../owner/content/MapPage";
// import SettingsUser from "../owner/content/settings/SettingsUser";
// import Api from "../../api/ApiUsers";
// import {BrowserRouter, Routes, Route} from "react-router-dom";


class OwnerDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      link_dist: '',
      link_owner: '',
    }
    // this.logIn = this.logIn.bind(this);
    // this.onChange = this.onChange.bind(this);
    // this.getUserData = this.getUserData.bind(this);
    // this.my = this.my.bind(this);
  }
  // onChange(field_name){
  //   let ttl = 1 //hour
  //   let role = 'owner' //hour
  //   Api.generateLinkRegistration(ttl, role).then(res => {
  //     this.setState({link_dist: res.link})
  //   })
  //   // if (field_name === 'dist') {
  //   //   this.setState({link_dist: 'disitr'})
  //   // } else {
  //   //   this.setState({link_owner: 'owner'})
  //   // }
  //   // console.log(this.state)
  // }


  render() {
    return (
      <div>
        {/*<ProSidebarProvider>*/}
           <HeaderOwner setPage={this.setPage}/>
          <div style={{ display: "flex", height: "100vh" }}>
            <SideBarOwner setPage={this.setPage}/>
            <section style={{ width: "100%" }}>
              <Routes>
                <Route path="companys" element={<CompanyPage/>}/>
                {/*<Route path="billing" element={<BillingContent/>}/>*/}
                <Route path="skud/rfid" element={<RfidPage/>}/>
                {/*<Route path="skud/rfid/add" element={<RfidAddPage/>}/>*/}
                <Route path="skud/statistic" element={<RfidStatistic/>}/>


                {/*<Route path="map" element={<MapPage/>}/>*/}

                {/*<Route path="settings" element={<SettingsUser/>}/>*/}
              </Routes>
            </section>
          </div>
        {/*</ProSidebarProvider>*/}
      </div>
    )
  }
}

OwnerDashboard.contextType = UserContext;

export default OwnerDashboard
