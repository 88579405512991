class Api {
  constructor (url, headers) {
    this._url = url
    this._headers = headers
  }

  checkResponse (res) {
    return new Promise((resolve, reject) => {
      if (res.status === 204) {
        return resolve(res)
      }
      const func = res.status < 400 ? resolve : reject
      res.json().then(data => func(data))
    })
  }

  getUserMeData () {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/users/me/',
      {
        headers: {
          ...this._headers,
          'Authorization': `_Token_ac ${access}`,
        },
      }
    ).then(this.checkResponse)
  }

  getUserCompany () {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/user/company/',
      {
        headers: {
          ...this._headers,
          'Authorization': `_Token_ac ${access}`,
        },
      }
    ).then(this.checkResponse)
  }


  getUserTariff () {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/user/tariff/',
      {
        headers: {
          ...this._headers,
          'Authorization': `_Token_ac ${access}`,
        },
      }
    ).then(this.checkResponse)
  }
  getRfid (param, page=null, limit=null) {
    const access = localStorage.getItem('_stoda_')
    let url = '/api-front/skud/rfid/?active=' + param
    if (page) {
      url += '?page=' + page
      if (limit) {
        url += '&limit=' + limit
      }
    }
    return fetch(
      this._url + url,
      {
        headers: {
          ...this._headers,
          'Authorization': `_Token_ac ${access}`,
        },
      }
    ).then(this.checkResponse)
  }

  changeRfid (id, data) {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/skud/rfid/' + id + '/',
      {
        headers: {
          ...this._headers,
          'Authorization': `_Token_ac ${access}`,
        },
        method: "PATCH",
        body: data
      }
    ).then(this.checkResponse)
  }

  deleteRfid (id) {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/skud/rfid/' + id + '/',
      {
        headers: {
          ...this._headers,
          'Authorization': `_Token_ac ${access}`,
        },
        method: "DELETE",
      }
    ).then(this.checkResponse)
  }

  uploadRfidExcel (file) {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/skud/rfid/add/',
      {
        headers: {
          "X-Binarybox-Api-Key": this._headers['X-Binarybox-Api-Key'],
          'Authorization': `_Token_ac ${access}`,
        },
        method: "POST",
        body: file
      }
    ).then(this.checkResponse)
  }

  getRfidGroup(company_id) {
     const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/skud/rfid/group/?company_id=' + company_id,
      {
        headers: {
          ...this._headers,
          'Authorization': `_Token_ac ${access}`,
        },
        method: "GET",
      }
    ).then(this.checkResponse)
  }

  getActivateRfid(data) {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/skud/rfid/activate/',
      {
        headers: {
          "X-Binarybox-Api-Key": this._headers['X-Binarybox-Api-Key'],
          'Authorization': `_Token_ac ${access}`,
        },
        method: "POST",
        body: data
      }
    ).then(this.checkResponse)
  }

  getProductForCardGroup(company_id) {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/skud/rfid/product/?company_id=' + company_id,
      {
        headers: {
          ...this._headers,
          'Authorization': `_Token_ac ${access}`,
        },
        method: "GET",
      }
    ).then(this.checkResponse)
  }

  addGroup(data) {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/skud/rfid/add-group/',
      {
        headers: {
          "X-Binarybox-Api-Key": this._headers['X-Binarybox-Api-Key'],
          'Authorization': `_Token_ac ${access}`,
        },
        method: "POST",
        body: data
      }
    ).then(this.checkResponse)
  }

  getActivator(company_id) {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/skud/rfid/activator/?company_id=' + company_id,
      {
        headers: {
          ...this._headers,
          'Authorization': `_Token_ac ${access}`,
        },
        method: "GET",
      }
    ).then(this.checkResponse)
  }

  getProlongate(type_card, number) {
     const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/skud/rfid/prolongate/?type=' + type_card + '&number=' + number,
      {
        headers: {
          ...this._headers,
          'Authorization': `_Token_ac ${access}`,
        },
        method: "GET",
      }
    ).then(this.checkResponse)
  }

  getGuidActivator(company_id) {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/skud/rfid/get-activator/?company_id=' + company_id,
      {
        headers: {
          ...this._headers,
          'Authorization': `_Token_ac ${access}`,
        },
        method: "GET",
      }
    ).then(this.checkResponse)
  }

  getRfidActivator(data) {
    const access = localStorage.getItem('_stoda_')
    return fetch(
      this._url + '/api-front/skud/rfid/get-activator/',
      {
        headers: {
          "X-Binarybox-Api-Key": this._headers['X-Binarybox-Api-Key'],
          'Authorization': `_Token_ac ${access}`,
        },
        method: "POST",
        body: data
      }
    ).then(this.checkResponse)
  }

  getRfidDispanseByDay (date_from=null, date_to=null, tz='UTC') {
      const access = localStorage.getItem('_stoda_')
      return fetch(
        this._url
        + '/api-front/skud/statistic/dispanse?date_from='
        + date_from + '&date_to=' + date_to + '&tz=' + tz,
        {
          headers: {
            ...this._headers,
            'Authorization': `_Token_ac ${access}`,
          },
          method: "GET",
        }
      ).then(this.checkResponse)
  }


  loginUser (email, password, remember) {
    return fetch(
      this._url + '/api-front/users/token',
      {
        method: 'POST',
        headers: {...this._headers,},
        body: JSON.stringify({
          email: email,
          password: password,
          remember: remember
        })
      }
    ).then(this.checkResponse)
  }



}
export default new Api(
  process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000',
  {
    'content-type': 'application/json',
    'X-Binarybox-Api-Key': process.env.REACT_APP_API_KEY || ''
  }
  )

