import React, { Component } from 'react';
import UserContext from "../../user-context";
// import Api from "../../api/ApiUsers";
// import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
// import AdminDashboard from "./admin/AdminDashboard";
// import DistribDashboard from "./distributer/DistribDashboard";
import OwnerDashboard from "./owner/OwnerDashboard";
// import MangerDashboard from "./manager/MangerDashboard";
// import PageLoading from "../static-page/PageLoading";

class Dashboard extends Component {

  render() {
      // if (this.context.user.role === 'admin') {
      //   return <AdminDashboard/>
      // } else
      // if (this.context.user.role === 'distributer') {
      //   return <DistribDashboard/>
      // }
      // else
        if (this.context.user.role === 'owner') {
          return <OwnerDashboard/>
        }
      // } else if (this.context.user.role === 'manager') {
      //   return <MangerDashboard/>
      // }
    }
  }

Dashboard.contextType = UserContext;

export default Dashboard
