import React, {Component} from 'react';
import UserContext from "../../../../../../user-context";
// import { MaterialReactTable } from 'material-react-table';
import {Row} from "react-bootstrap";
import Api from "../../../../../../api/ApiUsers";
// import TariffCard from "./cards/TariffCard";
// import classes from "./modal-window/style.module.css";
// import PayBalance from "./modal-window/PayBalance";
// import ReactModal from "react-modal";
import {Box, IconButton, Typography} from "@mui/material";
import {Delete as DeleteIcon, Info as InfoIcon} from "@mui/icons-material";
import {MaterialReactTable} from "material-react-table";
import {Link} from "react-router-dom";
// import classes from "./modal-window/style.module.css";
// import ReactModal from "react-modal";
// import CreateUser from "./modal-window/CreateUser";
// import InfoUser from "./modal-window/InfoUser";

class ActiveRfid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      rowCount: 0,
      pageCount: 0,
      // page_index: 0,
      // page_size: 0
      // tariffs: [],
      // showPayBalance: false,
    }
    this.myRef = React.createRef();
    this.getColumnRfid = this.getColumnRfid.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }



  componentDidMount() {
    Api.getRfid(1).then(res => {
      this.setState({
          data: res.results,
          rowCount: res.total_objects,
          pageCount: res.total_pages,
          // page_index: 0,
          // page_size: 10
        })
      }
    ).catch(err => {
      if (err.code === 'token_not_valid') {
        this.context.setMyState({isLogged: false})
      }
    })
  }


  getColumnRfid() {
    return [
      {
        accessorKey: 'id', //access nested data with dot notation
        header: 'ID',
        size: 50,
      },
      {
        accessorKey: 'company.name', //access nested data with dot notation
        header: 'Компания',
        size: 150,
      },
      {
        accessorKey: 'number',
        header: 'RFID ID',
        size: 150,
      },
      {
        accessorKey: 'is_active',
        header: 'Активна',
        size: 150,
        Cell: ({ cell }) =>
          cell.getValue() ? 'Ok' : 'No'
      },
      {
        accessorKey: 'group.name',
        header: 'Группа',
        size: 150,
      },
      {
        accessorKey: 'balance',
        header: 'Баланс',
        size: 150,
      },
      {
        accessorKey: 'description', //normal accessorKey
        header: 'Примечание',
        size: 200,
        Cell: ({ cell }) =>
          cell.getValue() ?? '-'
      },
      {
        accessorKey: 'date_add',
        header: 'Дата создания',
        size: 150,
        Cell: ({ cell }) =>
          new Date(cell.getValue()).toLocaleString()
      },
    ];
  }

  // myRef = node => {
  //   // same as Hooks example, re-render on changes
  //   // console.log('Change')
  // };


  handlePaginationChange(){
    // console.log(e)
    // e.preventDefault();
    let pag = this.myRef.current.getState().pagination
    console.log(pag)
    // if (
    //   pag.pageIndex !== this.state.page_index
    //   || pag.pageSize !== this.state.page_size
    // ) {
      Api.getRfid(1, pag.pageIndex+1, pag.pageSize).then(
      res => {
        // this.page_index = pag.pageIndex
        let data_n = this.state.data.concat(res.results)
        this.setState({
          data: data_n,
          rowCount: res.total_objects,
          pageCount: res.total_pages,
          page_index: pag.pageIndex,
          page_size: pag.pageSize
        })
        this.myRef.current.setPagination({
          pageSize: pag.pageSize,
          pageIndex: pag.pageIndex
      })
      }
    ).catch(err => {
      if (err.code === 'token_not_valid') {
        this.context.setMyState({isLogged: false})
      }
    })
    }

  render() {
    return (
        <MaterialReactTable
          columns={this.getColumnRfid()}
          data={this.state.data}
          rowCount={this.state.rowCount}
          pageCount={this.state.pageCount}
          enableFullScreenToggle={false}
          enableExpandAll={false}
          // onPaginationChange={e => this.handlePaginationChange(e(this))}
          onPaginationChange={() => this.handlePaginationChange()}
          // onPaginationChange={this.handlePaginationChange}
          // onPaginationChange={}
          initialState={{
            density: 'compact',
            // pagination:
            //   pag?pag:{pageSize:10, pageIndex:0}
              // pageSize: this.state.page_size?this.state.page_size:10,
              // pageIndex: pag1
              // pageIndex: this.state.page_index?this.state.page_index:0

          }}
          // state={{pagination: pag?pag:{pageSize:10, pageIndex:0}}}
          //     pageSize: this.state.page_size?this.state.page_size:10,
          //     pageIndex: this.state.page_index?this.state.page_index:0
          //   }}}
          renderDetailPanel={({ row }) => {
            if (row.getIsExpanded()) {
              return (
                <>
                <Box
                  sx={{
                    display: 'grid',
                    margin: 'auto',
                    gridTemplateColumns: '1fr 1fr',
                    width: '100%',
                  }}
                >
                  <div>Параметры группы</div>
                  <div>
                  <Typography>Кредитная: {row.original.group.is_credit? 'Да': 'Нет'}</Typography>
                  <Typography>Все напитки: {row.original.group.all_product? 'Да': 'Нет'}</Typography>
                  {row.original.group.all_product? '' :
                    <Typography>Товары: {row.original.group.device_product.map(
                      (row) => {
                        return row.product + ' '
                      }
                      )
                    }</Typography>
                  }
                  </div>

                  {/*<Typography>Контактное email: {row.original.contact_email}</Typography>*/}
                </Box>
                  {/*<div>Товары</div>*/}
                  {/*<ProductCodeTable dataMatrix={row.original} />*/}
                </>
              )
            } else {
              return <div>Not expanded</div>
            }
          }}
          enableDensityToggle={false}
          positionActionsColumn="last"
          tableInstanceRef={this.myRef}
          enableRowActions
          renderRowActions={({ row, table }) => {
            if (row) {
              <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
                <IconButton
                  color="primary"
                  // onClick={() =>
                  //   this.setState({
                  //     rowObject: row.original,
                  //     showInfoObject: true
                  //   })
                  //
                  // }
                >
                  <InfoIcon/>
                </IconButton>
                <IconButton
                  color="error"
                  // onClick={() => {
                  //   data.splice(row.index, 1); //assuming simple data table
                  //   setData([...data]);
                  // }}
                >
                  <DeleteIcon/>
                </IconButton>
              </Box>
            }
          }
          }
        />
    )
  }
}

ActiveRfid.contextType = UserContext;

export default ActiveRfid
