import React, {Component} from 'react';
import UserContext from "../../../../../user-context";
// import { MaterialReactTable } from 'material-react-table';
import {Row} from "react-bootstrap";
import Api from "../../../../../api/ApiUsers";
// import TariffCard from "./cards/TariffCard";
import classes from "./modal-window/style.module.css";
import PayBalance from "./modal-window/PayBalance";
import ReactModal from "react-modal";
// import classes from "./modal-window/style.module.css";
// import ReactModal from "react-modal";
// import CreateUser from "./modal-window/CreateUser";
// import InfoUser from "./modal-window/InfoUser";
import { Tab, Tabs } from '@liinkiing/react-tabs'


class BillingContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tariffs: [],
      showPayBalance: false,
    }
    this.getTariffCard = this.getTariffCard.bind(this);
    this.handleOpenPayBalance = this.handleOpenPayBalance.bind(this);
    this.handleClosePayBalance = this.handleClosePayBalance.bind(this);
  }

  handleOpenPayBalance () {
    this.setState({ showPayBalance: true });
  }
  handleClosePayBalance () {
    this.setState({showPayBalance: false});
  }


  componentDidMount() {
    Api.getUserTariff().then(res => {
      this.setState({tariffs: res})
      }
    ).catch(err => {
      if (err.code === 'token_not_valid') {
        this.context.setMyState({isLogged: false})
      }
    })
  }


   getTariffCard() {
    var handleOpen = this.handleOpenPayBalance
     if (this.state.tariffs) {
       return (
         <Tabs
           initial={{opacity: 0, x: 40}}
           animate={{opacity: 1, x: 0}}
           exit={{opacity: 0, x: 40}}
         >
           {this.state.tariffs.map(function (card) {
             return (
               <Tab key={card.id}>
                 <Tab.Title>{card.company? card.company.name:'Для всех Компаний'}</Tab.Title>
                 <Tab.Content>
                   <div>Тариф: {card.tariff.name}</div>
                   <div>Стоимость: {card.cost}</div>
                   <div>Баланс: {card.balance}</div>
                   <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                   onClick={() => handleOpen()}
                    type="submit">Пополнить</button>
                 </Tab.Content>
               </Tab>
             );
           })}
         </Tabs>
       )
     } else {
       return <div>У Вас нет Тарифов</div>
     }
  }


  render() {
    return (
      <>
      <Row style={{ margin: "20px" }}>
        <this.getTariffCard/>
      </Row>
      <ReactModal
         isOpen={this.state.showPayBalance}
         className={classes.ModalPass}
         overlayClassName={classes.BackdropPass}
         // contentLabel="Minimal Modal Example"
         onRequestClose={this.handleClosePayBalance}
         shouldCloseOnOverlayClick={true}
      >
        <PayBalance closeModal={this.handleClosePayBalance}/>
      </ReactModal>
      </>
    )
  }
}

BillingContent.contextType = UserContext;

export default BillingContent
