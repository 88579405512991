import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';
// import "flatpickr/dist/themes/material_green.css";
import "../../../../../../../scss/DatePickerStyle.css"
import { Russian } from "flatpickr/dist/l10n/ru.js"
// import {Component} from "@types/react";
// import equal from 'fast-deep-equal'

class Datepicker extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   default_data: [new Date().setDate(new Date().getDate() - 6), new Date()],
    // }
    this.get_options = this.get_options.bind(this);
  }


  get_options () {
    return {
    mode: 'range',
    locale: Russian,
    static: true,
    monthSelectorType: 'static',
    dateFormat: 'M j, Y',
    maxDate: new Date().getTime(),
    defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date().setDate(new Date().getDate())],
    prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady: (selectedDates, dateStr, instance) => {
    instance.element.value = dateStr.replace('to', '-');
    const customClass = '';
    instance.calendarContainer.classList.add(`flatpickr-${customClass}`);
    },
    onChange: (selectedDates, dateStr, instance) => {
        instance.element.value = dateStr.replace('to', '-');
    },
      onClose: (selectedDates, dateStr, instance) => {
        if (selectedDates.length > 1) {
          this.props.set_date([selectedDates[0].getTime(), selectedDates[1].getTime()])
        }
      }
  }
  }


  render() {

    return (
      // <div className="relative">
      <>
        {/*<div className="relative-icon">*/}
        <div style={{ flex: 'auto', alignSelf: 'center'}}>
          <svg className="w-4 h-4 fill-current text-slate-500 dark:text-slate-400 ml-3" viewBox="0 0 16 16">
            <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
          </svg>
         </div>
        {/*</div>*/}
        <Flatpickr className="form-input pl-9 dark:bg-slate-800 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200 font-medium w-[15.5rem]"
                   options={this.get_options()}
                    style={{flex: 1}}
          />

      </>
      // </div>
    );
  }
}


export default Datepicker;