import React, { Component } from 'react';
import UserContext from "../../../../../../user-context";
// import Api from "../../../../../../api/ApiUsers";
// import ApiAdmin from "../../../../../../api/ApiAdmin";
// import {withRouter} from "../../utils/withRouter";
import classes from "./style.module.css";
import Select from 'react-select';
import {Button} from "react-bootstrap";

class PayBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tariff_name: '',
      users_list: [],
      user_id: '',
      agent_pay_list: [],
      agent_pay_list_choice: [],
      agent_fiscal_list: [],
      agent_fiscal_list_choice: [],
      page: 0,
      pass_error: ''
    }
    // this.onChange = this.onChange.bind(this);
    // this.checkEmail = this.checkEmail.bind(this);
    this.PaymentPage = this.PaymentPage.bind(this);
    // this.getUsers = this.getUsers.bind(this);
    // this.getAgentPay = this.getAgentPay.bind(this);
    // this.getAgentFiscal = this.getAgentFiscal.bind(this);
    // this.getObject = this.getObject.bind(this);
  }


  // onChange(e, field_name){
  //   this.setState({[field_name]: e})
  // }
  //
  // getUsers() {
  //   Api.getMyUsersList().then(res => {
  //     let new_data = res.filter(res => res.tariff === null).map(({ id, email, first_name }) => (
  //       {value: id, label: first_name? email + '/' + first_name : email})
  //     )
  //     this.setState({ users_list: new_data })
  //   }).catch(err => {
  //     if (err.code === 'token_not_valid') {
  //       this.context.setMyState({isLogged: false})
  //     }
  //   })
  // }
  // getAgentPay() {
  //   ApiAdmin.getAgentPay().then(res => {
  //     let new_data = res.map(({ id, name }) => (
  //       {value: id, label: name})
  //     )
  //     this.setState({ agent_pay_list: new_data })
  //   }).catch(err => {
  //     if (err.code === 'token_not_valid') {
  //       this.context.setMyState({isLogged: false})
  //     }
  //   })
  // }
  // getAgentFiscal() {
  //    ApiAdmin.getAgentFiscal().then(res => {
  //     let new_data = res.map(({ id, name }) => (
  //       {value: id, label: name})
  //     )
  //     this.setState({ agent_fiscal_list: new_data })
  //   }).catch(err => {
  //     if (err.code === 'token_not_valid') {
  //       this.context.setMyState({isLogged: false})
  //     }
  //   })
  // }

  // createTariff(e) {
  //   e.preventDefault();
  //   if (
  //     !this.state.tariff_name
  //     || !this.state.user_id
  //   ) {
  //     this.setState({pass_error: "Все поля должны быть заполнены"})
  //   } else {
  //     this.setState({pass_error: ''})
  //     let data = {
  //       name: this.state.tariff_name,
  //       user: this.state.user_id,
  //       agent_pay: this.state.agent_pay_list_choice.map(({value}) => value),
  //       agent_fiscal: this.state.agent_fiscal_list_choice.map(({value}) => value),
  //     }
  //     Api.createTariff(data).then(res => {
  //       if (res.status === 'Ok') {
  //         this.setState({page: 1})
  //         setTimeout(() => this.props.closeModal(), 5000)
  //       } else if (res.status === 'user_exist') {
  //         this.setState({pass_error: 'У этого пользователя уже есть Тариф'})
  //       }
  //     }).catch(err => {
  //     if (err.code === 'token_not_valid') {
  //       this.context.setMyState({isLogged: false})
  //     }
  //   })
  //   }
  // }

  PaymentPage() {
     return (
       <form className="pt-3" onSubmit={this.createTariff}>
         <div className="form-group">
            <label htmlFor="tariff_name">Пополнить</label>
            <input type="text" className="form-control form-control-lg" id="tariff_name"
                   // value={this.state.tariff_name || ''}
                   // onChange={ (e) => this.onChange(e.target.value, 'tariff_name')}
                   // placeholder="Название"
            />
         </div>

         <div className="my-3">
           {/*<Button*/}
           {/*  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"*/}
           {/*  type="submit"*/}
           {/*>Создать</Button>*/}
         </div>
         {
           this.state.pass_error ?
            <h4>{ this.state.pass_error }</h4>
           :
            <div></div>
         }
       </form>
     )
  }

  // succesPage() {
  //   return <h4>Тариф успешно создан.</h4>
  // }

  render() {
    return (
      <div>
        <div className={classes.Header}>
            <div className={classes.Title}>Пополнение Баланса</div>
            <div>
              <span className={classes.CloseButtonPass}
                onClick={() => this.props.closeModal()}
              >
                x
              </span>
            </div>
        </div>
        <div className={classes.ModalDescPass}>
            {
              (() => {
                if (this.state.page === 0) {
                  return this.PaymentPage()
                } else if (this.state.page === 1) {
                  return this.succesPage()
                }
              })()
            }
        </div>
      </div>
    )
  }
}

PayBalance.contextType = UserContext;

export default PayBalance
