import React, { Component } from 'react';
import UserContext from "../../user-context";
import Api from "../../api/ApiUsers";
// import { useParams } from "react-router-dom";
import {withRouter} from "../../utils/withRouter";
import Page404 from "../static-page/Page404";
import PageLoading from "../static-page/PageLoading";
import {Navigate} from "react-router-dom";

class ConfirmSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      not_found: false,
      loading: true,
      redirect: false
    }
  }

  componentDidMount() {
    const {hash, token} = this.props.params
    Api.confirmSignUp(hash, token).then(res => {
      if (res.data === 'ok') {
        this.setState({confirm: true, loading: false})
      } else if (res.data === 'repeated') {
        this.setState({not_found: true, loading: false})
      } else if (res.data === 'error') {
        this.setState({not_found: true, loading: false})
      }
    }).catch(err => {
      if (err.code === 'token_not_valid') {
        this.context.setMyState({isLogged: false})
      }
    })
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to="/login" replace />;
    } else if (this.state.confirm) {
      setTimeout(() => this.setState({ redirect: true }), 5000)
      return <h1>Подтверждение удалось, вы будете перенаправлены</h1>
    } else if (this.state.loading) {
      return <PageLoading/>
    } else if (this.state.not_found) {
      return <Page404/>
    }
  }
}

ConfirmSignUp.contextType = UserContext;

export default withRouter(ConfirmSignUp)
