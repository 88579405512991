import React, { Component } from 'react';
import UserContext from "../../../../../../user-context";
import classes from "./style.module.css";
import Api from "../../../../../../api/ApiUsers";
import {
  Button,
  Radio,
  Table,
  TableHead,
  TableRow,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  TableCell,
  TableBody} from "@mui/material";
import {Form} from "react-bootstrap";

class ActivateRfid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: '',
      rfids_id: '',
      groups: [],
      disButtonGroup: false,
      disButtonAddGroup: true,
      page: 0,
      groupId: '',
      balanceError: false,
      countActivate: '',

      products: [],
      // disButtonActivate: true,

      allProductChecked: true,
      showProduct: false,
      errorChoiceProduct: false,
      statusActivate: false,

    }
    // this.getDataGroup = this.getDataGroup.bind(this);
    this.activate = this.activate.bind(this);
    this.addGroup = this.addGroup.bind(this);
    // this.disabledButton = this.disabledButton.bind(this);
    this.getPage = this.getPage.bind(this);
    this.activatePage = this.activatePage.bind(this);
    // this.activateComplitedPage = this.activateComplitedPage.bind(this);
    this.addGroupPage = this.addGroupPage.bind(this);
    this.allProductCheck = this.allProductCheck.bind(this);
    this.getProduct = this.getProduct.bind(this);
    this.changeError = this.changeError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBalanceChange = this.handleBalanceChange.bind(this);
    this.activateComplite = this.activateComplite.bind(this);
  }

  componentDidMount() {
    var company_id = this.props.rfids[0].original.company.id
    var rfid_id = this.props.rfids.map(row => row.original.id)
    Api.getRfidGroup(company_id).then(res => {
       this.setState({groups: res, rfids_id: rfid_id, company: company_id})
       }
     ).catch(err => {
       if (err.code === 'token_not_valid') {
         this.context.setMyState({isLogged: false})
       }
     })

    // ((row) => {
    //             alert('activating ' + row.getValue('number'));
    //           })

    // Api.getRfid(1).then(res => {
    //   this.setState({data: res})
    //   }
    // ).catch(err => {
    //   if (err.code === 'token_not_valid') {
    //     this.context.setMyState({isLogged: false})
    //   }
    // })
  }
  // disabledButton() {
  //   this.setState({disButtonActivate: false})
  // }

  getPage() {
    if (this.state.page === 0) {
      return this.activatePage()
    } else if (this.state.page === 1) {
      return this.activateComplite()
    } else if (this.state.page === 2) {
      return this.addGroupPage()
    }
  }

  handleChange(field, event) {
        this.setState({[field] : Number(event.target.value)});
    }

  handleBalanceChange(e) {
    if (e.target.value && e.target.value < 0) {
      this.setState({balanceError: true})
    } else {
      this.setState({balanceError: false})
    }
  }

  activatePage() {
    // var disButton = this.disabledButton
    if (this.state.groups) {
      var content = (
        <>
          <div>Выберите группу к которой будут принадлежать Карты</div>
          <div>Задайте Баланс, если необходимо переопределить стартовый баланс</div>
        <Form onSubmit={(e) => this.activate(e)}>
          <FormControl>
          <Table aria-label="simple table">
               <TableHead>
                  <TableRow>
                     <TableCell align="center"/>
                     <TableCell>Название группы</TableCell>
                     <TableCell align="center">Тип</TableCell>
                     <TableCell align="center">Напитки</TableCell>
                     <TableCell align="center">Стартовый Баланс</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {this.state.groups.map(row => (
                  <TableRow key={row.id}>
                     <TableCell component="th" scope="row">
                        <Radio
                           value={row.id}
                           checked={row.id != this.state.groupId ? false : true}
                           onChange={this.handleChange.bind(this, 'groupId')}
                        />
                     </TableCell>
                     <TableCell align="center">{row.name}</TableCell>
                     <TableCell align="center">{row.is_credit ? 'Кредитная' : 'НеКредитная'}</TableCell>
                     <TableCell align="center">{row.all_product ? 'Все напитки' : row.device_product.map(function (it) {
                          return <div key={it.product} >{it.product}</div>})}</TableCell>
                    <TableCell align="center">{row.start_balance}</TableCell>
                  </TableRow>
                  ))}
               </TableBody>
            </Table>

            </FormControl>
            <div style={{marginTop: '10px'}}>
              <FormControl error={this.state.balanceError} variant="standard">
              <Input id="balance" placeholder="Изменить баланс" name="balance"
                      type="number"
                      error={this.state.balanceError}
                      onChange={this.handleBalanceChange}
                      aria-describedby="helper"
              />
                {!this.state.balanceError ?
                  <FormHelperText id="helper">Переопределить стартовый баланс</FormHelperText>
                  :
                  <FormHelperText id="helper">Баланс не может быть меньше 0</FormHelperText>
                }

              </FormControl>
            </div>
            <div className={classes.ModalFooterPass}>
              <Button
                color="info"
                variant="contained"
                onClick={() => {
                  this.setState({page: 2})
                }}
                // disabled={this.state.disButtonGroup}
                disabled={true}
              >
                <s>Добавить группу</s>
              </Button>
              <Button
                color="success"
                disabled={this.state.groupId && !this.state.balanceError ? false : true}
                variant="contained"
                type="submit"
              >
                Активировать
              </Button>
            </div>
          </Form>
        </>
      )
    } else {
      var content = (<></>)
    }
    return content
  }

  activateComplite () {
    return (
      <>
        <div>Активировано карточек: {this.state.countActivate}</div>
        <Button
          color="success"
          variant="contained"
          onClick={() => this.props.closeModal()}
        >
          Закрыть
        </Button>
      </>
    )
  }


  allProductCheck (e) {
    if (e.target.checked) {
      this.setState({showProduct: false, allProductChecked: true})
    } else {
      this.setState({showProduct: true, allProductChecked: false})
      Api.getProductForCardGroup(this.state.company).then(res => {
        if (res) {
          this.setState({products: res})
        }
      }
    ).catch(err => {
      this.setState({statusUpload: err});
      if (err.code === 'token_not_valid') {
        this.context.setMyState({isLogged: false})
      }
    })
    }
  }

   changeError () {
          this.setState({errorChoiceProduct: false})
        }

  getProduct() {
    if (this.state.showProduct) {
      if (this.state.products.length > 0) {
       var change = this.changeError
        var contex = (
          <>
            Выберите напитки которые будут доступны
            {this.state.errorChoiceProduct ? <div>!!! Необходимо выбрать хотя бы один !!!</div> : <></>}
            {this.state.products.map(function (item) {
                return (
                  <div>
                    <label htmlFor={`product_${item.id}`}>{item.product}</label>
                    <input id={`product_${item.id}`} name="products" value={item.id} type="checkbox" onChange={() => {change()}}/>
                  </div>
                )
              }
            )
            }
          </>
        )
      } else {
         var contex = (
            <div>
              У Компании не задана Торговая Матрица.
            </div>
         )
      }
      return contex
    }
  }

  addGroupPage() {
    return (
      <>
        <div>
          <thead>
            <tr>
              <th scope="col">Название группы</th>
              <th scope="col">Тип</th>
              <th scope="col">Напитки</th>
            </tr>
          </thead>
          <tbody>
              {
                this.state.groups.map(function (item) {
                  return (
                    <tr>
                      <td>{item.name}</td>
                      <td>{item.is_credit ? 'Кредитная' : 'НеКредитная'}</td>
                      <td>{item.all_product ? 'Все напитки' : item.device_product.map(function (it) {
                        return it.product
                      })}</td>
                    </tr>
                  )
                })
              }
          </tbody>
        </div>
        <form className="pt-3" onSubmit={(e) => this.addGroup(e)}>
          <div>
            <label htmlFor="name">Название группы</label>
            <input id="name" name="name" type="text" required onChange={() => this.setState({disButtonAddGroup: false})}/>
          </div>
          <div>
             <label htmlFor='is_credit'>Кредитный rfid?</label>
             <input id="is_credit" name="is_credit" type="checkbox"/>
          </div>
          <div>
             <label htmlFor='all_product'>Доступны все напитки?</label>
             <input id="all_product" type="checkbox" name="all_product" onChange={(e) => {this.allProductCheck(e)}} checked={this.state.allProductChecked}/>
          </div>
          {this.getProduct()}
          <div className={classes.ModalFooterPass}>
            <Button
              color="info"
              variant="contained"
              onClick={() => {this.setState({page: 0})}}
            >
              Назад
            </Button>
            <Button
              color="success"
              disabled={this.state.disButtonAddGroup}
              variant="contained"
              type="submit"
            >
              Добавить группу
            </Button>
          </div>
        </form>
      </>
    )
  }


  activate(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append('rfids', JSON.stringify(this.state.rfids_id))
    formData.append('group', JSON.stringify(this.state.groupId))
    Api.getActivateRfid(formData).then(res => {
      if (res.status === 'ok') {
        this.setState({
          statusActivate: true, countActivate: res.msg,
          // disButtonActivate: true,
          // disButtonGroup: true,
          page: 1
        })
        // setTimeout(() => this.props.closeModal(), 5000)
        // setTimeout(() => this.props.loadData(), 5000)
      }
     }
     ).catch(err => {
       if (err.code === 'token_not_valid') {
         this.context.setMyState({isLogged: false})
       }
     })
  }

  addGroup(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (!formData.get('all_product') && !formData.get('products')) {
      this.setState({errorChoiceProduct: true})
    } else {
      formData.append('company_id', this.state.company)
        Api.addGroup(formData).then(res => {
        if (res.status === 'ok') {
          Api.getRfidGroup(this.state.company).then(res => {
         this.setState({groups: res, page: 0})
           }
         ).catch(err => {
           if (err.code === 'token_not_valid') {
             this.context.setMyState({isLogged: false})
           }
         })

      }
     }
     ).catch(err => {
       if (err.code === 'token_not_valid') {
         this.context.setMyState({isLogged: false})
       }
     })
    }
  }

  render() {
    return (
      <div>
        <div className={classes.Header}>
            <div className={classes.Title}>Активация Rfid</div>
            <div>
              <span className={classes.CloseButtonPass}
                onClick={() => this.props.closeModal()}
              >
                x
              </span>
            </div>
        </div>
        <div className={classes.ModalDescPass}>
          {this.getPage()}
        </div>
      </div>
    )
  }
}

ActivateRfid.contextType = UserContext;

export default ActivateRfid
