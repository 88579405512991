import React, {Component} from 'react';
import UserContext from "../../../../../user-context";
import {Row} from "react-bootstrap";
import NotActiveRfid from "./component/NotActiveRfid";
import ActiveRfidTable from "./component/ActiveRfidTable";

class RfidPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: '',
    }
    this.updateRefresh = this.updateRefresh.bind(this);
  }

  updateRefresh (value) {
    this.setState({refresh: value})
  }

  render() {
    return (
      <>
      <Row style={{ margin: "20px" }}>
        Активные
        <ActiveRfidTable refresh={this.state.refresh}/>
        Новые
        <NotActiveRfid setRefresh={this.updateRefresh}/>
      </Row>
      </>
    )
  }
}

RfidPage.contextType = UserContext;

export default RfidPage
