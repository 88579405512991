import ApiUsers from "../../../../../../api/ApiUsers";
import classes from "../modal-window/style.module.css";
import ReactModal from "react-modal";
import ActivateRfid from "../modal-window/ActivateRfid";

import React, { useEffect, useMemo, useState, useContext } from 'react';
import { MaterialReactTable } from 'material-react-table';
import UserContext from "../../../../../../user-context";

import {
  Box,
  IconButton,
  Tooltip,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  Checkbox
} from "@mui/material";
import {Delete as DeleteIcon, Edit} from "@mui/icons-material";



const NotActiveRfid = ({setRefresh}) => {
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [refect, setRefect] = useState(0);
  const [changeModalOpen, setChangeModalOpen] = useState(false);
  const [editRow, setEditRow] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState([]);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [showActivateModel, setShowActivateModel] = useState(false);
  const [activateRfids, setActivateRfids] = useState([]);
  const [rowSelection, setRowSelection] = useState({});

  const context = useContext(UserContext)

  const setUserContext = () => {
    context.setMyState({isLogged: false})
  }

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(
        '/qr-admin/api-front/skud/rfid/',
        // '/api-front/skud/rfid/',
        process.env.REACT_APP_API_BASE || 'http://127.0.0.1:8000'
      );
      url.searchParams.set(
        'active',
        `0`,
      );
      url.searchParams.set(
        'page',
        `${pagination.pageIndex + 1}`,
      );
      url.searchParams.set('limit', `${pagination.pageSize}`);
      // url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      // url.searchParams.set('globalFilter', globalFilter ?? '');
      url.searchParams.set('globalFilter', globalFilter ?? '');
      url.searchParams.set('sorting', JSON.stringify(sorting ?? []));

      try {
        const access = localStorage.getItem('_stoda_')
        const response = await fetch(
            url.href,
            {
              method: 'GET',
              headers: {
              'Authorization': `_Token_ac ${access}`,
              'content-type': 'application/json',
              'X-Binarybox-Api-Key': process.env.REACT_APP_API_KEY || ''
            },
          }
        );
        const json = await response.json();
        if (json.code === 'token_not_valid') {
          setUserContext()
        } else {
          setData(json.results);
          setRowCount(json.total_objects);
          setPageCount(json.total_pages);
        }
      } catch (error) {
        setIsError(true);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    refect
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id', //access nested data with dot notation
        header: 'ID',
        size: 50,
        Cell: ({ cell }) =>
          cell.getValue() ?? '-'
      },
      {
        accessorKey: 'company', //access nested data with dot notation
        header: 'Компания',
        size: 150,
        Cell: ({ cell }) =>
          cell.getValue()?.name ?? '-'
      },
      {
        accessorKey: 'number',
        header: 'RFID ID',
        enableSorting: false,
        size: 150,
        Cell: ({ cell }) =>
          cell.getValue() ?? '-'
      },
      {
        accessorKey: 'is_active',
        header: 'Активна',
        size: 150,
        Cell: ({ cell }) =>
          cell.getValue() ? '✅' : '❌'
      },
      {
        accessorKey: 'group',
        header: 'Группа',
        size: 150,
        Cell: ({ cell }) =>
          cell.getValue()?.name ?? '-'
      },
      {
        accessorKey: 'balance',
        header: 'Баланс',
        enableSorting: false,
        size: 150,
        Cell: ({ cell }) =>
          cell.getValue() ?? '-'
      },
      {
        accessorKey: 'description', //normal accessorKey
        header: 'Примечание',
        size: 200,
        Cell: ({ cell }) =>
          cell.getValue() ?? '-'
      },
      {
        accessorKey: 'date_add',
        header: 'Дата создания',
        size: 150,
        Cell: ({ cell }) =>
          cell.getValue() ? new Date(cell.getValue()).toLocaleString() : '-'
      },
    ],
    [],
  );

  const closeActivateModal = () => {
    setRowSelection({})
    setRefresh(1)
    setRefect(oldKey => oldKey +1)
    setShowActivateModel(false)
  }

  return (
    <>
    <MaterialReactTable
      columns={columns}
      data={data}
      enableRowSelection
      selectAllMode="all"
      enableColumnFilters={false}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableExpandAll={false}
      isMultiSortEvent={() => true}
      maxMultiSortColCount={2}
      positionActionsColumn="last"
      enableRowActions
      renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => {
                setChangeModalOpen(true);
                setEditRow(row.original)
              }}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => {
                setDeleteModalOpen(true);
                setDeleteRow(row.original)
              }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      initialState={{ showColumnFilters: false, density: 'compact' }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={rowCount}
      pageCount={pageCount}
      onRowSelectionChange={setRowSelection}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
        rowSelection
      }}
      renderTopToolbarCustomActions={({table}) => {
            const handleActivate = () => {
              // console.log(table.getSelectedRowModel().flatRows)
              // table.getSelectedRowModel().flatRows.map((row) => {
              //   alert('activating ' + row.getValue('number'));
              // });
              // return <Navigate to="/activate" replace/>
              // return <Link to="activate" props="adlj"/>
              setActivateRfids(table.getSelectedRowModel().flatRows)
              setShowActivateModel(true)
            };

          return (
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            {/*<Link to="add">*/}
                <Button color="info" disabled={true} variant="contained"><s>Добавить</s></Button>
            {/*</Link>*/}
            <Button
              color="success"
              disabled={table.getIsAllRowsSelected() === table.getIsSomeRowsSelected()}
              // disabled={true}
              onClick={handleActivate}
              variant="contained"
            >
              Активировать
            </Button>
          </div>
        );
      }}
    />
    <ReactModal
      isOpen={showActivateModel}
      className={classes.ModalPass}
      overlayClassName={classes.BackdropPass}
      onRequestClose={closeActivateModal}
      shouldCloseOnOverlayClick={true}
    >
     <ActivateRfid rfids={activateRfids} closeModal={closeActivateModal}/>
    </ReactModal>
    <ChangeModal
        data={editRow}
        open={changeModalOpen}
        setRefech={setRefect}
        onClose={() => setChangeModalOpen(false)}
      />
    <DeleteModal
        data={deleteRow}
        open={deleteModalOpen}
        setRefech={setRefect}
        onClose={() => setDeleteModalOpen(false)}
      />
  </>
  );
};

export const ChangeModal = ({ open, data, onClose, setRefech }) => {
  const [values, setValues] = useState();
  const [nameError, setNameError] = useState(false);
  const context = useContext(UserContext)

  useEffect(() => {
    return setValues(data)
  }, [
    data
  ]);

  const setUserContext = () => {
    context.setMyState({isLogged: false})
  }

  const handleSubmit = () => {
    var data = {
      balance: values.balance,
      is_active: values.is_active,
      description: values.description
    }
    ApiUsers.changeRfid(values.id, JSON.stringify(data)).then(
      res => {
      }
    ).catch(err => {
      if (err.code === 'token_not_valid') {
        setUserContext()
      }
    })
    setRefech(oldKey => oldKey +1)
    onClose();
  };

  const handleBalanceChange = e => {
    setValues({...values, [e.target.name]: e.target.value})
    if (!e.target.value || e.target.value < 0) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };


  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Редактирование Новых Карты</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '100px', sm: '160px', md: '200px' },
              gap: '1.0rem',
            }}
          >
            {data ? <>
              <TextField
                key={data.id}
                label='ID'
                name="id"
                value={data.id ?? ''}
                disabled={true}
              />
            <TextField
                key={data?.company?.id}
                label='Компания'
                name={data?.company?.name}
                value={data?.company?.name ?? ''}
                disabled={true}
              />
            <TextField
                key={data.number}
                label='Rfid'
                name="number"
                value={data?.number ?? ''}
                disabled={true}
              />
            <TextField
              type="number"
              key={data.balance}
              label='Баланс'
              name="balance"
              value={data?.balance ?? 0}
              disabled={true}
            />
            <TextField
              key={data.description}
              label='Примечание'
              name="description"
              value={values?.description ?? ''}
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
            />
            <div>
              <label htmlFor="is_active">Активна</label>
              <Checkbox
                key='is_active'
                label='Активна'
                name='is_active'
                checked={data?.is_active ?? false}
                disabled={true}
              />
            </div>
              </> : <></>}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Отмена</Button>
        <Button color="success" onClick={handleSubmit} variant="contained">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteModal = ({ open, data, onClose, setRefech }) => {
  const context = useContext(UserContext)

  const setUserContext = () => {
    context.setMyState({isLogged: false})
  }

  const handleSubmit = () => {
    ApiUsers.deleteRfid(data.id).then(
      res => {
        setRefech(oldKey => oldKey +1)
        onClose();
      }
    ).catch(err => {
      if (err.code === 'token_not_valid') {
        setUserContext()
      }
      setRefech(oldKey => oldKey +1)
      onClose();
    })

  };


  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Удаление</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '100px', sm: '160px', md: '200px' },
              gap: '1.0rem',
            }}
          >
            {data ? <>
              <TextField
                key={data.id}
                label='ID'
                name="id"
                value={data.id ?? ''}
                disabled={true}
              />
            <TextField
                key={data?.company?.id}
                label='Компания'
                name={data?.company?.name}
                value={data?.company?.name ?? ''}
                disabled={true}
              />
            <TextField
                key={data.number}
                label='Rfid'
                name="number"
                value={data?.number ?? ''}
                disabled={true}
              />
            <TextField
              type="number"
              key={data.balance}
              label='Баланс'
              name="balance"
              value={data?.balance ?? 0}
              disabled={true}
            />
            <TextField
              key={data.description}
              label='Примечание'
              name="description"
              value={data?.description ?? ''}
              disabled={true}
            />
            <div>
              <label htmlFor="is_active">Активна</label>
              <Checkbox
                key='is_active'
                label='Активна'
                name='is_active'
                checked={data?.is_active ?? false}
                disabled={true}
              />
            </div>
              </> : <></>}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Отмена</Button>
        <Button color="error" onClick={handleSubmit} variant="contained">
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotActiveRfid;
