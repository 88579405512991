import {Menu, Sidebar, MenuItem, SubMenu} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import React, { Component } from 'react';
import UserContext from "../../../../user-context";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';



class SideBarOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: window.location.pathname,
      sidebarOpen: true
    }
  }

  onClickPath (path) {
    if (path !== window.location.pathname) {
       this.setState({path: path})
    } else {
      this.setState({path: window.location.pathname})
    }
  }

  render() {
    return (
        <Sidebar
          collapsed={!this.state.sidebarOpen}
        >
          <Menu menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    color: disabled ? 'rgb(164,185,227)' : '#2b2b2b',
                    backgroundColor: active ? '#a4b9e3' : undefined,
                  };
                if (level === 1)
                  return {
                    color: disabled ? '#a4b9e3' : '#2b2b2b',
                    backgroundColor: active ? '#a4b9e3' : undefined,
                  };
              },
            }}>
            <SubMenu icon={<LocationCityIcon/>} label="Корпорация" active={this.state.path === '/companys' || this.state.path === '/billing'}>
              <MenuItem component={<Link to="/companys"/>} onClick={(e) => this.onClickPath('/companys')} active={this.state.path === '/companys'} >Компания</MenuItem>
              {/*<MenuItem component={<Link to="/billing"/>} onClick={(e) => this.onClickPath('/billing')} active={this.state.path === '/billing'} >Биллинг</MenuItem>*/}
            </SubMenu>
            {
                  this.context.user.services?.includes(1) ?
                    <SubMenu icon={<QrCodeScannerIcon/>} label="QR Коды" active={this.state.path === '/qr/cassa' || this.state.path === '/qr/statistic'}>
                      <MenuItem component={<Link to="qr/cassa"/>} onClick={(e) => this.onClickPath('/qr/cassa')} active={this.state.path === '/qr/cassa'} >Кассы</MenuItem>
                      <MenuItem component={<Link to="qr/statistic"/>} onClick={(e) => this.onClickPath('/qr/statistic')} active={this.state.path === '/qr/statistic'}>Статистика</MenuItem>
                    </SubMenu>
                    : <></>
            }
            {
                  this.context.user.services?.includes(2) ?
                    <SubMenu icon={<SettingsRemoteIcon/>} label="СКУД система" active={this.state.path === '/skud/rfid' || this.state.path === '/skud/statistic'}>
                      <MenuItem component={<Link to="skud/rfid"/>} onClick={(e) => this.onClickPath('/skud/rfid')} active={this.state.path === '/skud/rfid'}>Rfid</MenuItem>
                      <MenuItem component={<Link to="skud/statistic"/>} onClick={(e) => this.onClickPath('/skud/statistic')} active={this.state.path === '/skud/statistic'}>Статистика</MenuItem>
                    </SubMenu>
                    : <></>
            }
          </Menu>
        </Sidebar>
    )
  }
}

SideBarOwner.contextType = UserContext;

export default SideBarOwner



